import { Card, Table, Button, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { Truncate } from "../../components/Truncate";
import { STATUS } from "../../Constants";
import { getInshortsAction } from "../../redux/reducers/inshorts";
import { EyeOutlined } from "@ant-design/icons";
import InshortsPreview from "./InshortsPreview";

export const Inshorts = () => {
  const dispatch = useDispatch();

  const { inshortsList, getInshortsStatus } = useSelector(
    (state) => state.inshorts
  );

  const [isPreviewModalOpen, setisPreviewModalOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState({});

  const handleCancel = () => {
    setisPreviewModalOpen(false);
  };

  const handlePreview = (previewObj) => {
    setPreviewItem(previewObj);
    setisPreviewModalOpen(true);
  };

  useEffect(() => {
    dispatch(getInshortsAction());
  }, []);
  const approvedInshortList = inshortsList?.filter((item) => item.approved);
  const rejectedInshortList = inshortsList?.filter((item) => item.rejected);

  return (
    <div>
      <CommonPageHeader title="Inshorts" />
      <br />
      <Card>
        <br />
        <Table
          bordered
          loading={getInshortsStatus === STATUS.FETCHING}
          pagination={{ position: ["bottomCenter"] }}
          dataSource={[...approvedInshortList, ...rejectedInshortList]}
        >
          <Table.Column
            title="Inshort Title"
            width={200}
            dataIndex="title"
            render={(d) => d}
          />
          <Table.Column
            title="Publish Status"
            width={120}
            align="center"
            dataIndex="approved"
            render={(d) => {
              return (
                <>
                  {d ? (
                    <Tag color="green">PUBLISHED</Tag>
                  ) : (
                    <Tag color="red">NOT PUBLISHED</Tag>
                  )}
                </>
              );
            }}
          />
          <Table.Column
            title="Created By"
            dataIndex="userName"
            width={200}
            render={(d) => <Truncate length="100">{d}</Truncate>}
          />
          <Table.Column
            width={120}
            title="Date"
            align="center"
            dataIndex="updatedAt"
            // render={(d) => moment(d.publishedAt).format("DD-MM-YYYY")} outdated code
            render={(d) => moment(d).format("DD-MM-YYYY")} // updated code
            sorter={(a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)}
            defaultSortOrder="descend"
            key="date"
          />
          <Table.Column
            title="Actions"
            width={120}
            align="center"
            dataIndex="approved"
            render={(d, obj) => {
              return (
                <>
                  <Button
                    onClick={() => handlePreview(obj)}
                    style={{
                      borderWidth: 0,
                    }}
                    icon={<EyeOutlined />}
                  />
                  {d ? (
                    <Button style={{ width: 80, borderRadius: 8 }}>
                      Unlist
                    </Button>
                  ) : (
                    <Button style={{ width: 80, borderRadius: 8 }}>
                      Publish
                    </Button>
                  )}
                </>
              );
            }}
          />
        </Table>
      </Card>
      <InshortsPreview
        handleCancel={handleCancel}
        isPreviewModalOpen={isPreviewModalOpen}
        previewItem={previewItem}
      />
    </div>
  );
};
