import { useEffect, useState } from "react";
import React from "react";
import {
  Button,
  Card,
  Input,
  Form,
  Table,
  Tag,
  Drawer,
  Space,
  Tooltip,
} from "antd";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import "./Inshorts.css";
import { BlogEditor } from "../../components/TinymceEditor";
import { UploadImageBox } from "../../components/UploadImageBox";
import moment from "moment";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addInshortsAction,
  getInshortsAction,
  resetAddInshortsStatus,
  resetSendApprovalRequestStatus,
  sendApprovalRequestAction,
} from "../../redux/reducers/inshorts";
import { STATUS } from "../../Constants";
import {
  ConfirmAlert,
  ErrorMessage,
  SuccessMessage,
} from "../../Constants/CommonAlerts";
import _ from "lodash";
import { removeHtmlTagsAndEntities } from "../../utils/FileHelper";
import InshortsPreview from "../Inshorts/InshortsPreview";
import { getAllTeachers } from "../../redux/reducers/doubts";
import { getApiUrl } from "../../utils/universalHelpers";

const multiSelectOptions = [
  {
    value: "Sports",
    label: "Sports",
  },
  {
    value: "Current Affairs",
    label: "Current Affairs",
  },
  {
    value: "Articles",
    label: "Articles",
  },
  {
    value: "Politics",
    label: "Politics",
  },
  {
    value: "History",
    label: "History",
  },
  {
    value: "Jurisdiction",
    label: "Jurisdiction",
  },
  {
    value: "Academics",
    label: "Academics",
  },
  {
    value: "GENERAL STUDIES",
    label: "GENERAL STUDIES",
  },
];

const InshortCreator = () => {
  const dispatch = useDispatch();
  const {
    addInshortsStatus,
    inshortsList,
    getInshortsStatus,
    sendApprovalRequstStatus,
  } = useSelector((state) => state.inshorts);
  const { _id, name } = useSelector((state) => state.user.user);

  const staff = useSelector((state) => state.doubts.allTeachers);

  useEffect(() => {
    dispatch(getAllTeachers());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    userName: name,
    userId: _id,
  });

  const [openEditInshortDrawer, setOpenEditInshortDrawer] = useState();
  const [isPreviewModalOpen, setisPreviewModalOpen] = useState(false);
  const [previewObj, setPreviewObj] = useState({});

  const teachers = staff
    ?.filter((item) => item.user !== null && item.user !== undefined)
    .map((item) => {
      const name = item.user.name.trim();
      const desc =
        item.staffDesc === undefined ? "coco" : item.staffDesc.trim();
      return {
        value: `${name} • (${desc})`,
        label: `${name} • (${desc})`,
      };
    });

  const handlePreview = (inshortObj) => {
    setPreviewObj(inshortObj);
    setisPreviewModalOpen(true);
  };

  const handleCancel = () => {
    setisPreviewModalOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const resetForm = () => {
    setFormData({
      userName: name,
      userId: _id,
      title: null,
      blogValue: "",
      audioUrl: null,
      tags: null,
      imageUrl: null,
      contentBy: null,
      videoSource: null,
      jwId: null,
      youtubeUrl: null,
    });
  };

  useEffect(() => {
    dispatch(getInshortsAction());
  }, [dispatch, getInshortsAction]);

  // clean up on save inshorts api
  useEffect(() => {
    if (addInshortsStatus === STATUS.SUCCESS) resetForm();
    return () => {
      dispatch(resetAddInshortsStatus());
    };
  }, [dispatch, addInshortsStatus]);

  // clean up on send approval request api
  useEffect(() => {
    if (sendApprovalRequstStatus === STATUS.SUCCESS) resetForm();
    return () => {
      dispatch(resetSendApprovalRequestStatus());
    };
  }, [dispatch, sendApprovalRequstStatus]);

  const saveInshort = () => {
    dispatch(addInshortsAction(formData));
  };

  const sendForApproval = () => {
    dispatch(sendApprovalRequestAction(formData));
    dispatch(getInshortsAction());
  };

  const handleBlogChange = (e) => {
    setFormData({ ...formData, blogValue: e });
  };

  const handleContentOwner = (value) => {
    setFormData({ ...formData, contentBy: value });
  };

  const handleImageChange = (e) => {
    const {
      response: { url },
    } = e.file;
    setFormData({ ...formData, imageUrl: url });
  };

  const handleImageRemove = () => {
    setFormData({ ...formData, imageUrl: null });
  };

  const handleTags = (e) => {
    setFormData({ ...formData, tags: e });
  };

  const handleSelect = (e) => {
    setFormData({ ...formData, videoSource: e });
  };

  const onInshortEditCliked = (d) => {
    setOpenEditInshortDrawer(d?._id ? d : false);
  };

  const removeInshort = async (id) => {
    try {
      const response = await fetch(getApiUrl(`/inshorts-content/?id=${id}`), {
        method: "DELETE",
      });
      if (!response.ok) {
        ErrorMessage();
      } else {
        SuccessMessage("Inshort Successfully Deleted!");
        dispatch(getInshortsAction());
      }
    } catch (err) {
      ErrorMessage(err);
    }
  };
  const deleteInshort = (d) => {
    ConfirmAlert(
      () => removeInshort(d._id),
      "Are you sure you want to delete?",
      null
    );
  };

  const [descriptions, openDescription] = useState([]);

  const _openDescirpton = (obj) => {
    openDescription((d) => _.xor(d, [obj._id]));
  };

  // filter inshorts uploaded by current user and show in table
  const filteredContent = inshortsList?.filter((item) => item.userId === _id);

  const {
    blogValue,
    title = "",
    audioUrl = "",
    tags = [],
    jwId = "",
    youtubeUrl = "",
    videoSource,
    contentBy,
    bunnyId,
  } = formData;

  return (
    <div>
      <CommonPageHeader title="Inshorts Uploader" />
      <br />

      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 28 }}>
        <div className="form-container">
          {/** left */}
          <div style={{ flex: 1 }}>
            <Form.Item required label="Enter Title">
              <Input
                className="input"
                id="title"
                onChange={handleChange}
                placeholder="Title ..."
                value={title}
              />
            </Form.Item>
            <Form.Item required label="Add Content">
              <BlogEditor
                disableAlert
                value={blogValue}
                onChange={handleBlogChange}
              />
            </Form.Item>
          </div>
          {/** right */}
          <div
            style={{
              flex: 1,
              padding: 12,
            }}
          >
            <Form.Item required label="Add Image">
              <UploadImageBox
                onRemove={handleImageRemove}
                ratio={"1:1"}
                disableAlert
                getImage={handleImageChange}
              />
            </Form.Item>

            <Form.Item label="Video Source" placeholder="Select Video Source">
              <Select
                disabled={audioUrl?.length > 0}
                name="videoSource"
                placeholder="Select Video Source"
                allowClear
                value={videoSource}
                onChange={(e) => handleSelect(e)}
              >
                <option value={"jw"}>JW</option>
                <option value={"youtube"}>YouTube</option>
                <option value={"bunny"}>Bunny</option>
              </Select>
            </Form.Item>
            {videoSource === "jw" ? (
              <Form.Item label="JW ID">
                <Input
                  onChange={handleChange}
                  placeholder="Enter JW Id"
                  id="jwId"
                  type="text"
                  value={jwId}
                  disabled={audioUrl?.length > 0}
                />
              </Form.Item>
            ) : videoSource === "youtube" ? (
              <Form.Item label="YouTube URL">
                <Input
                  id="youtubeUrl"
                  type="text"
                  placeholder="Enter YouTube URL"
                  value={youtubeUrl}
                  disabled={audioUrl?.length > 0}
                  onChange={handleChange}
                />
              </Form.Item>
            ) : videoSource === "bunny" ? (
              <Form.Item label="Bunny Id">
                <Input
                  id="bunnyId"
                  type="text"
                  placeholder="Enter Bunny Id"
                  value={bunnyId}
                  disabled={audioUrl?.length > 0}
                  onChange={handleChange}
                />
              </Form.Item>
            ) : null}
            <Form.Item label="Add Audio Url">
              <Input
                className="input"
                value={audioUrl}
                disabled={jwId?.length > 0 || youtubeUrl?.length > 0}
                id="audioUrl"
                onChange={handleChange}
                placeholder="Audio Url ..."
              />
            </Form.Item>
            <Form.Item required label="Add Tags">
              <Select
                mode="multiple"
                value={tags}
                style={{
                  width: "100%",
                }}
                onChange={handleTags}
                placeholder="Please select"
                options={multiSelectOptions}
              />
            </Form.Item>

            <Form.Item label="Content Author">
              <Select
                showSearch
                placeholder="Select Creator"
                onChange={handleContentOwner}
                options={teachers}
                value={contentBy}
              />
            </Form.Item>
          </div>
        </div>
        <br />
        <Form.Item wrapperCol={{ offset: 10, span: 20 }}>
          <div className="button-container">
            <Button
              style={{ marginRight: 8 }}
              onClick={saveInshort}
              className="button"
            >
              Save As Draft
            </Button>
            <Button
              className="button"
              // htmlType="submit"
              onClick={sendForApproval}
              type="primary"
              loading={addInshortsStatus === STATUS.FETCHING}
            >
              Send for Approval
            </Button>
          </div>
        </Form.Item>
      </Form>

      <br />

      <Card style={{ borderRadius: 12 }}>
        <br />
        <Table
          bordered
          loading={getInshortsStatus === STATUS.FETCHING}
          pagination={{ position: ["bottomCenter"] }}
          dataSource={filteredContent}
        >
          <Table.Column
            title="My Content"
            width={200}
            dataIndex="title"
            render={(d) => d}
          />
          <Table.Column
            width="40%"
            title="Description"
            dataIndex="blogValue"
            render={(d, obj) => {
              const removedHtml = removeHtmlTagsAndEntities(d);
              let readMore =
                _.findIndex(descriptions, (des) => des === obj._id) != -1;

              return d?.length > 200 ? (
                <div>
                  {readMore
                    ? removedHtml
                    : removedHtml.substring(0, 200) + "..."}
                  {readMore ? (
                    <Button
                      type="link"
                      color="blue"
                      style={{ padding: 0 }}
                      size="xs"
                      onClick={() => _openDescirpton(obj)}
                    >
                      read less
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      color="blue"
                      style={{ padding: 0 }}
                      size="xs"
                      onClick={() => _openDescirpton(obj)}
                    >
                      read more
                    </Button>
                  )}
                </div>
              ) : (
                removedHtml
              );
            }}
          />
          <Table.Column
            title="Status"
            width={100}
            align="center"
            render={(d) => {
              return (
                <>
                  {d.approved ? (
                    <Tag color="green">Approved</Tag>
                  ) : d.rejected ? (
                    <Tag color="red">Rejected</Tag>
                  ) : (
                    <Tag color="blue">Pending</Tag>
                  )}
                </>
              );
            }}
          />

          <Table.Column
            width={100}
            title="Created At"
            dataIndex="createdAt"
            render={(d) => moment(d).format("DD-MM-YYYY")}
            sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            key="date"
          />
          <Table.Column
            title="Actions"
            key="actions"
            dataIndex={"_id"}
            width={100}
            render={(d, obj) => {
              const disabledInshorts =
                obj?.approved || (!obj.approved && !obj.rejected);

              return (
                <Space>
                  <Tooltip title="Preview">
                    <Button
                      onClick={() => handlePreview(obj)}
                      style={{
                        borderWidth: 0,
                      }}
                      icon={<EyeOutlined />}
                    />
                  </Tooltip>
                  <Tooltip title="Edit">
                    <Button
                      disabled={disabledInshorts}
                      icon={<EditOutlined />}
                      onClick={() => onInshortEditCliked(obj)}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <Button
                      disabled={disabledInshorts}
                      icon={<DeleteOutlined />}
                      onClick={() => deleteInshort(obj)}
                    />
                  </Tooltip>
                </Space>
              );
            }}
          />
        </Table>
      </Card>
      {openEditInshortDrawer ? (
        <EditInshort
          teachers={teachers}
          visible={openEditInshortDrawer}
          closeModal={onInshortEditCliked}
          currentInshort={openEditInshortDrawer}
        />
      ) : null}
      <InshortsPreview
        handleCancel={handleCancel}
        isPreviewModalOpen={isPreviewModalOpen}
        previewItem={previewObj}
      />
    </div>
  );
};

const EditInshort = ({ visible, closeModal, currentInshort, teachers }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ ...currentInshort });
  const {
    blogValue,
    title,
    audioUrl,
    contentBy,
    youtubeUrl,
    jwId,
    tags,
    _id,
    videoSource,
  } = currentInshort;

  const handleImageChange = (e) => {
    const {
      response: { url },
    } = e.file;
    setFormData({ ...formData, imageUrl: url });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };
  const handleTags = (e) => {
    setFormData({ ...formData, tags: e });
  };

  const updateInshort = async () => {
    try {
      const response = await fetch(getApiUrl(`/inshorts-content/${_id}`), {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        SuccessMessage("Inshort Updated Successfully");
        dispatch(getInshortsAction());
      } else {
        ErrorMessage("Failed to Update");
      }
    } catch (err) {
      console.error("Error occured while updating", err);
    }
  };

  const handleSelect = (e) => {
    setFormData({ ...formData, videoSource: e });
  };

  const handleBlogChange = (e) => {
    setFormData({ ...formData, blogValue: e });
  };

  const sendForApproval = () => {
    dispatch(sendApprovalRequestAction(formData));
  };

  const handleContentOwner = (value) => {
    setFormData({ ...formData, contentBy: value });
  };

  return (
    <Drawer
      width="50%"
      open={visible}
      onClose={closeModal}
      title={"Update Inshort"}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        onSubmitCapture={updateInshort}
      >
        <Form.Item
          label="Title"
          initialValue={title}
          rules={[{ required: true, message: "Please fill in the field." }]}
          name="title"
        >
          <Input id="title" onChange={handleChange} placeholder="Title" />
        </Form.Item>
        <Form.Item
          label="Description"
          initialValue={blogValue}
          name="blogValue"
        >
          <BlogEditor
            disableAlert
            value={blogValue}
            onChange={handleBlogChange}
          />
        </Form.Item>
        <Form.Item required label="Add Image">
          <UploadImageBox disableAlert getImage={handleImageChange} />
        </Form.Item>
        <Form.Item
          initialValue={audioUrl}
          name="audioUrl"
          label="Add Audio Url"
        >
          <Input
            className="input"
            id="audioUrl"
            onChange={handleChange}
            placeholder="Audio Url ..."
          />
        </Form.Item>
        <Form.Item initialValue={tags} name="tags" required label="Add Tags">
          <Select
            mode="multiple"
            value={tags}
            style={{
              width: "100%",
            }}
            onChange={handleTags}
            placeholder="Please select"
            options={multiSelectOptions}
          />
        </Form.Item>
        <Form.Item
          label="Video Source"
          name="videoSource"
          initialValue={videoSource}
          placeholder="Select Video Source"
        >
          <Select
            disabled={audioUrl?.length > 0}
            name="videoSource"
            placeholder="Select Video Source"
            value={videoSource}
            onChange={(e) => handleSelect(e)}
          >
            <option value={"jw"}>JW</option>
            <option value={"youtube"}>YouTube</option>
            <option value={"bunny"}>Bunny</option>
          </Select>
        </Form.Item>
        {formData.videoSource === "jw" ? (
          <Form.Item initialValue={jwId} name="jwId" label="JW ID">
            <Input
              onChange={handleChange}
              placeholder="Enter JW Id"
              id="jwId"
              name="jwId"
              value={jwId}
              disabled={audioUrl?.length > 0}
            />
          </Form.Item>
        ) : formData.videoSource === "youtube" ? (
          <Form.Item
            name="youtubeUrl"
            initialValue={youtubeUrl}
            label="YouTube URL"
          >
            <Input
              id="youtubeUrl"
              type="text"
              placeholder="Enter YouTube URL"
              value={youtubeUrl}
              disabled={audioUrl?.length > 0}
              onChange={handleChange}
            />
          </Form.Item>
        ) : null}

        <Form.Item
          name="contentBy"
          initialValue={contentBy}
          label="Content Author"
        >
          <Select
            showSearch
            placeholder="Select Creator"
            onChange={handleContentOwner}
            options={teachers}
            value={contentBy}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 16, offset: 6 }}>
          <Button htmlType="submit">Update</Button>
          <Button
            onClick={sendForApproval}
            style={{ marginLeft: 12 }}
            htmlType="submit"
          >
            Send for approval
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default InshortCreator;
