export const ROUTES = {
  INSTITUTE_ADD: "/institute/add",
  INSTITUE_LIST: "/institute/list",
  MANAGE_BATCHES: { key: "/manage-batches/:id?", rootPath: "/manage-batches" },
  ADD_STAFF: "/add-staff",
  SEARCH_STUDENT: "/student/search",
  STUDENT_PROFILE: "/student/profile/:id",
  ADD_GROUP: { key: "/manage-groups/:id?", rootPath: "/manage-groups" },
  ALUMNIS_LIST: "/alumnis",
  ADD_STUDENT: "/add-student",
  LMS_CONFIG: "/lms-config",
  MANAGE_COURSES: "/courses",
  MANAGE_STAFF: "/list-staff",
  CONFIG_DATA: "/config-data",
  UPLOAD_BASECHAPTERS: "/upload-basechapters",
  LIST_BASECHAPTERS: "/list-basechapters",
  CKEDITOR: "/ckeditor",
  ADD_QUESTIONS: "/add-questions",
  ADD_PARAGRAPH: "/add-paragraph",
  LIST_PARAGRAPH: "/manage-paragraph",
  ADD_CHAPTER_TEMPLATE: "/add-chapter-template",
  LIST_CHAPTER_TEMPLATE: {
    key: "/list-chapter-template/:pageNumber?",
    rootPath: "/list-chapter-template",
  },
  UPDATE_CHAPTER_TEMPLATE: "/update-chapter-template/:id",
  LIST_QUESTIONS: "/list-questions/:pageNumber?",
  ADD_PACKAGE: "/add-package",
  LIST_PACKAGES: {
    key: "/list-packages/:pageNumber?",
    rootPath: "/list-packages",
  },
  LIST_CENTER: { key: "/list-center/:pageNumber?", rootPath: "/list-center" },
  UPDATE_PACKAGE: "/update-package/:step/:id",
  PREVIEW_PACKAGE: "/preview-package/:id/:preview",
  MANAGE_PRODUCTS: "/products",
  ADD_TEST: "/add-test",
  MANAGE_COUPONS: "/coupons/:page?",
  PROMO_COUPONS: "/promo-coupon/:page?",
  MANAGE_LEADS: "/leads",
  MANAGE_TESTS: "/manage-tests/:page?",
  UPDATE_TEST: "/update-test/:id/:step/:subject?",
  MANAGE_TAGS: "/tags",
  ADD_INSTRUCTIONS: "/test/instructions",
  MANAGE_ASSIGNMENTS: "/assignments",
  ANSWER_KEYS: "/answer-keys/:id",
  TEST_QUESTIONS: "/test-questions/:id",
  EXPORT_TEST: "/export-test-paper/:id",
  QAQC_TESTS: "/qaqc-tests/:page",
  VERIFY_TEST_QUESTIONS: "/verify-test-questions/:id",
  WEBSITE_DATA: "/website-data",
  PROMO_CODE: "/promo-code",
  STUDENTS_AND_RESULT: "/students-and-result/:id",
  DISCUSSION_TOPIC: "/discussion",
  QUESTION_TOPIC: "/question",
  DISCUSSION_SUB_TOPIC: "/sub-discussion/:community/:id",
  BULK_QUESTION_UPLOAD: "/bulk-question-upload",
  NOTICE: "/notice",
  EVENTS: "/events",
  NOTIFICATIONS: "/notifications",
  LIST_NOTIFICATIONS: "/list-notifications/:page?",
  USER_PROFILE: "/user-profile",
  PACKAGE_STUDENTS: "/package-students/:id/:page?",
  ORDER_HISTORY: "/order-history",
  FEEDBACKS: "/feedbacks",
  INSHORTS: "/inshorts",
  INSHORTS_CONTENT: "/inshorts-content",
  INSHORTS_MODERATOR: "/inshorts-moderator",
  SURVEY_FEEDBACKS: "/survey-feedback/*",
  SURVEY_FEEDBACKS_LIST: "/survey-feedback-list",
  FEEDBACK_REPORT: "/feedback-report",
  SPECIFIC_PACKAGES: "/SpecificPackages",
  OFFLINE_ORDERS: "/offline-orders",
  WALLET_REPORT: "/wallet-history",
  WALLET_OFFERS: "/wallet-offers",
  TICKETS: "/desk",
  STUDENT_TICKETS: "/student-desk/:id?",
  STUDENT_LIST: "/students",
  DOUBTS_PANEL: "/doubts",
  ASSIGNMENT_DETAILS: "/assignment/:id",
  ALL_LEADS: "/all-leads",
  EXAM_CONTENT: "/exam-content",
  CATEGORY: "/category",
  CHART_RESULT: "/chart-result",
  PRINT_XII_RESULT: "/print-result",
  X_COMPARE_RESULT: "/xcompare",
  PRINT_X_COMPARE_RESULT: "/print-xcompare",
  X_EXAM_RESULT: "/x-exam-result",
  PRINT_X_EXAM_RESULT: "/print-x-exam-result",
  SURVEY_REPORT: "/survey-report/:id",
  DISCOUNT_CONFIGS: "/discount-configs",
  EVENT_STUDENTS_LIST: "/event-student-list/:id",
  FRANCHISE_ENQUIRY_LIST: "/franchise",
  CAREER_JOBAPPLICATION_LIST: "/career",
  ENQUIRY_FORM: "/enquiry-form",
  LIST_ENQUIRY: "/list-enquiry",
  OFFLINE_COURSE: "/offline-course",
  OFFLINE_PAYMENT_REPORT: "/offline-payment-report",
  DEFAULTER_STUDENTS_REPORT: "/defaulter-students-report",
  INVENTORY_ITEM_LIST: "/inventory-item",
  INVENTORY_ITEM_GROUP: "/inventory-group",
  ADMISSION_PAYMENT: "/admission/payment/:userId/:courseId/:enquiryId",
  BATCH_MANAGEMENT: "/batch-management",
  BATCH_MANAGEMENT_LIST: "/batch-management-list",
  BATCH_SCHEDULE: "/batch-schedule",
  BATCH_SCHEDULE_LIST: "/batch-schedule-list",
  BATCH_TEACHERS_REVIEWS: "/batch-staff-reviews",
  ORDER_SUMMARY: "/order-summary",
};
