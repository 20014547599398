import { STATUS } from "../../../Constants";
import {
  ErrorMessage,
  FetchingMessage,
  SuccessMessage,
} from "../../../Constants/CommonAlerts";
import { apis } from "../../../services/api/apis";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

export const addInshortsAction = createAsyncThunk(
  "create/inshorts",
  async (payload, thunkAPI) => {
    const response = await apis.addInshortsApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const sendApprovalRequestAction = createAsyncThunk(
  "approval/inshorts",
  async (payload, thunkAPI) => {
    const response = await apis.sendInshortsApprovalRequestApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const getInshortsAction = createAsyncThunk(
  "get/inshorts",
  async (payload, thunkAPI) => {
    const response = await apis.getInshortsApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const approveInshortAction = createAsyncThunk(
  "inshorts/approve",
  async (payload, thunkAPI) => {
    const response = await apis.approveInshortApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const rejectInshortAction = createAsyncThunk(
  "inshorts/reject",
  async (payload, thunkAPI) => {
    const response = await apis.rejectInshortApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const updateInshortsAction = createAsyncThunk(
  "update/inshorts",
  async (payload, thunkAPI) => {
    const response = await apis.updateInshortsApi(payload);
    const { ok, problem, data } = response;
    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

export const removeInshortsAction = createAsyncThunk(
  "remove/inshorts",
  async (payload, thunkAPI) => {
    const response = await apis.deleteInshortsApi(payload);
    const { ok, problem, data } = response;

    if (ok) return data;
    else return thunkAPI.rejectWithValue(problem);
  }
);

const initialState = {
  inshortsList: [],
};

const inshortSlice = createSlice({
  name: "inshorts",
  initialState,
  reducers: {
    resetAddInshortsStatus: (state) => {
      state.addInshortsStatus = STATUS.NOT_STARTED;
    },
    resetSendApprovalRequestStatus: (state) => {
      state.sendApprovalRequstStatus = STATUS.NOT_STARTED;
    },
  },
  extraReducers: {
    [getInshortsAction.pending]: (state) => {
      state.getInshortsStatus = STATUS.FETCHING;
    },
    [getInshortsAction.fulfilled]: (state, action) => {
      state.getInshortsStatus = STATUS.SUCCESS;
      state.inshortsList = action.payload;
    },
    [getInshortsAction.rejected]: (state) => {
      state.getInshortsStatus = STATUS.FAILED;
    },

    [addInshortsAction.pending]: (state) => {
      FetchingMessage();
      state.addInshortsStatus = STATUS.FETCHING;
    },
    [addInshortsAction.fulfilled]: (state, action) => {
      SuccessMessage("Inshorts Added");
      state.inshortsList.push(action.payload);
      state.addInshortsStatus = STATUS.SUCCESS;
    },
    [addInshortsAction.rejected]: (state) => {
      ErrorMessage();
      state.addInshortsStatus = STATUS.FAILED;
    },
    [sendApprovalRequestAction.pending]: (state) => {
      state.sendApprovalRequstStatus = STATUS.FETCHING;
    },
    [sendApprovalRequestAction.fulfilled]: (state) => {
      SuccessMessage("Inshorts Added and sent for approval");
      state.sendApprovalRequstStatus = STATUS.SUCCESS;
    },
    [sendApprovalRequestAction.rejected]: (state) => {
      ErrorMessage();
      state.sendApprovalRequstStatus = STATUS.FAILED;
    },
    [rejectInshortAction.pending]: (state) => {
      state.rejectInshortStatus = STATUS.FETCHING;
    },
    [rejectInshortAction.fulfilled]: (state, action) => {
      ErrorMessage("Inshort Rejected");
      state.approvedInshortsList.push(action.payload);
      state.rejectInshortStatus = STATUS.SUCCESS;
    },
    [rejectInshortAction.rejected]: (state) => {
      state.rejectInshortStatus = STATUS.FAILED;
    },
    [approveInshortAction.pending]: (state) => {
      state.approveInshortStatus = STATUS.FETCHING;
    },
    [approveInshortAction.fulfilled]: (state, action) => {
      ErrorMessage("Inshort Rejected");
      state.approvedInshortsList.push(action.payload);
      state.approveInshortStatus = STATUS.SUCCESS;
    },
    [approveInshortAction.rejected]: (state) => {
      state.approveInshortStatus = STATUS.FAILED;
    },
  },
});

export const { resetAddInshortsStatus, resetSendApprovalRequestStatus } =
  inshortSlice.actions;
export const inshortsReducer = inshortSlice.reducer;
