import { EyeOutlined, ShareAltOutlined } from "@ant-design/icons";
import { CommonPageHeader } from "../../components/CommonPageHeader";
import { Card, Table, Button, Tag } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getInshortsAction } from "../../redux/reducers/inshorts";
import { STATUS } from "../../Constants";
import { ErrorMessage, SuccessMessage } from "../../Constants/CommonAlerts";
import { Truncate } from "../../components/Truncate";
import InshortsPreview from "../Inshorts/InshortsPreview";
import { getApiUrl } from "../../utils/universalHelpers";

const InshortModerator = () => {
  const { inshortsList, getInshortsStatus } = useSelector(
    (state) => state.inshorts
  );
  const dispatch = useDispatch();

  const [updateApprovalRequestList, setUpdateApprovalRequestList] = useState(
    []
  );

  useEffect(() => {
    dispatch(getInshortsAction());
  }, [dispatch]);

  const filteredList = inshortsList?.filter(
    (item) => item.approved || item.rejected
  );

  useEffect(() => {
    const filter = inshortsList?.filter(
      (item) => item.approvalRequest && !item.approved && !item.rejected
    );
    setUpdateApprovalRequestList(filter);
  }, [inshortsList]);

  const onRejectInshort = async (id) => {
    const response = await fetch(
      getApiUrl(`/inshorts-content/rejected/${id}`),
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      SuccessMessage("Inshort Rejected Successfully!");
      setUpdateApprovalRequestList((prevList) =>
        prevList.filter((item) => item._id !== id)
      );
      dispatch(getInshortsAction());
    } else {
      ErrorMessage();
    }
  };

  const onApproveInshort = async (id) => {
    const response = await fetch(
      getApiUrl(`/inshorts-content/approved/${id}`),
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      SuccessMessage("Inshort Approved Successfully!");
      setUpdateApprovalRequestList((prevList) =>
        prevList.filter((item) => item._id !== id)
      );
      dispatch(getInshortsAction());
    } else {
      ErrorMessage();
    }
  };

  const [isPreviewModalOpen, setisPreviewModalOpen] = useState(false);
  const [previewItem, setPreviewItem] = useState({});

  const handlePreview = (previewObj) => {
    setPreviewItem(previewObj);
    setisPreviewModalOpen(true);
  };

  const handleCancel = () => {
    setisPreviewModalOpen(false);
  };

  return (
    <div>
      <CommonPageHeader title="Inshorts Moderator" />
      <br />
      <Card>
        <br />
        <Table
          bordered
          loading={getInshortsStatus === STATUS.FETCHING}
          pagination={{ position: ["bottomCenter"] }}
          dataSource={updateApprovalRequestList}
        >
          <Table.Column
            title="Inshort Content"
            width={140}
            dataIndex="title"
            render={(d) => d}
          />
          <Table.Column
            title="Publish Action"
            width={200}
            dataIndex="_id"
            align="center"
            render={(id, obj) => {
              console.log("id -->", id);
              return (
                <>
                  <Button
                    onClick={() => handlePreview(obj)}
                    style={{
                      borderWidth: 0,
                    }}
                    icon={<EyeOutlined />}
                  />
                  <Button
                    style={{
                      marginLeft: 8,
                    }}
                    onClick={() => onApproveInshort(id)}
                  >
                    Approve
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    onClick={() => onRejectInshort(id)}
                  >
                    Reject
                  </Button>
                  <Button
                    style={{
                      borderWidth: 0,
                      marginLeft: 8,
                    }}
                    icon={<ShareAltOutlined />}
                  />
                </>
              );
            }}
          />
          <Table.Column
            title="Created By"
            dataIndex="userName"
            width={80}
            render={(d) => d}
          />
          <Table.Column
            width={150}
            title="Created At"
            dataIndex="createdAt"
            // render={(d) => moment(d.createdAt).format("DD-MM-YYYY")}
            render={(d) => moment(d).format("DD-MM-YYYY")}
            sorter={(a, b) => new Date(a.createdAt) - new Date(b.createdAt)}
            defaultSortOrder="descend"
            key="date"
          />

          <Table.Column
            title="Remarks"
            width={120}
            dataIndex="remarks"
            render={(d) => d}
          />
        </Table>
      </Card>

      <br />
      <Card>
        <Table
          bordered
          loading={getInshortsStatus === STATUS.FETCHING}
          pagination={{ position: ["bottomCenter"] }}
          dataSource={filteredList}
        >
          <Table.Column
            title="Inshort Contents"
            width={200}
            dataIndex="title"
            render={(d) => d}
          />
          <Table.Column
            title="Publish Status"
            width={120}
            align="center"
            dataIndex="approved"
            render={(d) => {
              return (
                <>
                  {d ? (
                    <Tag color="green">APPROVED</Tag>
                  ) : (
                    <Tag color="red">REJECTED</Tag>
                  )}
                </>
              );
            }}
          />
          <Table.Column
            title="Created By"
            dataIndex="userName"
            width={200}
            render={(d) => <Truncate length="100">{d}</Truncate>}
          />
          <Table.Column
            width={120}
            title="Date"
            align="center"
            dataIndex="updatedAt"
            // render={(d) => moment(d.publishedAt).format("DD-MM-YYYY")} outdated code
            render={(d) => moment(d).format("DD-MM-YYYY")} // updated code
            sorter={(a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)}
            defaultSortOrder="descend"
            key="date"
          />
          <Table.Column
            title="Actions"
            width={120}
            align="center"
            dataIndex="approved"
            render={(d, obj) => {
              return (
                <>
                  <Button
                    onClick={() => handlePreview(obj)}
                    style={{
                      borderWidth: 0,
                    }}
                    icon={<EyeOutlined />}
                  />
                  {d ? (
                    <Button
                      onClick={() => onRejectInshort(obj._id)}
                      style={{ width: 80, borderRadius: 8 }}
                    >
                      Unlist
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onApproveInshort(obj._id)}
                      style={{ width: 80, borderRadius: 8 }}
                    >
                      Publish
                    </Button>
                  )}
                </>
              );
            }}
          />
        </Table>
      </Card>
      <InshortsPreview
        handleCancel={handleCancel}
        isPreviewModalOpen={isPreviewModalOpen}
        previewItem={previewItem}
      />
    </div>
  );
};

export default InshortModerator;
