import { Modal } from "antd";
import { useEffect } from "react";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import { getYouTubeVideoId } from "../../utils/getYoutubeId";

const InshortsPreview = ({ previewItem, handleCancel, isPreviewModalOpen }) => {
  useEffect(() => {
    console.log("previewItem", JSON.stringify(previewItem));
  }, []);

  const YoutubeId = getYouTubeVideoId(previewItem?.youtubeUrl);

  const openJWPlayer = () => {
    const jwPlayerUrl = `https://cdn.jwplayer.com/players/${previewItem?.jwId}-7RHAqkfq.html`;
    window.open(jwPlayerUrl, "_blank");
  };

  return (
    <Modal
      title="Inshort Preview"
      onCancel={handleCancel}
      onOk={handleCancel}
      visible={isPreviewModalOpen} // Use `visible` instead of `open`
    >
      {previewItem && (
        <div>
          <h2>{previewItem.title}</h2>
          <p>
            Written by -{" "}
            <span style={{ fontWeight: "600" }}>
              | {previewItem.contentBy} |
            </span>
          </p>
          <div
            style={{
              borderRadius: 16,
              alignSelf: "center",
              marginTop: 12,
              margin: "auto",
            }}
          >
            <img
              width={300}
              height={300}
              src={previewItem.imageUrl}
              alt={previewItem.title} // Add alt attribute for accessibility
            />
          </div>

          <div>
            <p style={{ marginTop: 12 }}>
              {previewItem.videoSource === "youtube" && (
                <YoutubeEmbed embedId={YoutubeId} />
              )}

              {previewItem.videoSource === "jw" && (
                <a
                  href={`https://cdn.jwplayer.com/players/${previewItem?.jwId}-7RHAqkfq.html`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={openJWPlayer}
                >
                  Open JW Player Video
                </a>
              )}
            </p>
          </div>
          <div style={{ marginTop: 12 }}>
            {/* Render HTML content safely */}
            <div dangerouslySetInnerHTML={{ __html: previewItem.blogValue }} />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default InshortsPreview;
